<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: "",
  components: {
  },
  data: () => ({

  }),
}
</script>
